import React from 'react'

function Main() {
  return (
    <>
      <div class="container maintenance">
        <div>
          <h1 class="display-4">We'll Be Back Soon!</h1>
          <p class="lead">We're currently making some improvements to provide you with an even better experience. Thank you for your patience!</p>
          <p>In the meantime, feel free to check back shortly. See you soon!</p>
        </div>
      </div>
      {/* <div class="flex flex-col items-center min-h-[calc(100vh-44px)] justify-center bg-gray-100">
        <h1 class="text-5xl tracking-tight max-w-3xl font-semibold mb-4 mt-10">We'll be back.</h1>
        <p class="ml-4 text-gray-500 text-xl">We're busy updating the Apple Store for you and will be back soon.</p>
      </div> */}
    </>
  )
}

export default Main